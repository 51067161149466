<template>
<div class="container-fluid mt-5 pt-5">
      <div class="page-breadcrumb">
        <div class="col-12">
            <div class="row">
                <div class="col-7 align-self-center pl-4">
                    <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">config. e-mails</h3>
                    <div class="d-flex align-items-center">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb m-0 p-0">
                                <li class="breadcrumb-item"><a href="?app=dashboard.php">dashboard / assistência técnica / config. e-mails</a></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">
                            <div class="col-12 pb-4 px-2">
                                <ul class="nav nav-tabs nav-bordered mb-3">
                                    <li class="nav-item text-left align-self-end">
                                        <a href="#boasvindas" data-toggle="tab" aria-expanded="false" class="nav-link active d-flex">
                                            <i class="far fa-envelope font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Boas vindas</span>
                                        </a>
                                    </li>
                                    <li class="nav-item text-left align-self-end">
                                        <a href="#esqueceu" data-toggle="tab" aria-expanded="false" class="nav-link d-flex">
                                            <i class="far fa-frown font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Esqueceu a senha</span>
                                        </a>
                                    </li>
                                    <li class="nav-item text-left align-self-end">
                                        <a href="#abertura" data-toggle="tab" aria-expanded="false" class="nav-link d-flex">
                                            <i class="far fa-envelope-open font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Abertura</span>
                                        </a>
                                    </li>
                                    <li class="nav-item text-left align-self-end">
                                        <a href="#interacao" data-toggle="tab" aria-expanded="false" class="nav-link d-flex">
                                            <i class="far fa-comment-alt font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Interação</span>
                                        </a>
                                    </li>
                                    <li class="nav-item text-left align-self-end">
                                        <a href="#fechamento" data-toggle="tab" aria-expanded="false" class="nav-link d-flex">
                                            <i class="far fa-times-circle font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Fechamento</span>
                                        </a>
                                    </li>
                                    <li class="nav-item text-left align-self-end">
                                        <a href="#reabertura" data-toggle="tab" aria-expanded="false" class="nav-link d-flex">
                                            <i class="far fa-edit font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Reabertura</span>
                                        </a>
                                    </li>
                                    <li class="nav-item text-left align-self-end">
                                        <a href="#configuracoes" data-toggle="tab" aria-expanded="false" class="nav-link d-flex">
                                            <i class="far fa-sun font-20 align-middle mr-2"></i><span class="d-none d-lg-block"> Configurações</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div class="tab-content">
                                <div class="tab-pane show active" id="boasvindas">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-10 col-lg-8">
                                                    <div class="form-group">
                                                        <label>Título</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <textarea class="form-control" rows="7" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="submit" class="btn btn-info">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane" id="esqueceu">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-10 col-lg-8">
                                                    <div class="form-group">
                                                        <label>Título</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <textarea class="form-control" rows="7" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="submit" class="btn btn-info">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane" id="abertura">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-10 col-lg-8">
                                                    <div class="form-group">
                                                        <label>Título</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <textarea class="form-control" rows="7" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="submit" class="btn btn-info">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane" id="interacao">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-10 col-lg-8">
                                                    <div class="form-group">
                                                        <label>Título</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <textarea class="form-control" rows="7" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="submit" class="btn btn-info">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane" id="fechamento">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-10 col-lg-8">
                                                    <div class="form-group">
                                                        <label>Título</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <textarea class="form-control" rows="7" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="submit" class="btn btn-info">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <div class="tab-pane" id="reabertura">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-10 col-lg-8">
                                                    <div class="form-group">
                                                        <label>Título</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <textarea class="form-control" rows="7" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="submit" class="btn btn-info">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                                <div class="tab-pane" id="configuracoes">
                                    <form action="" class="px-2 needs-validation" novalidate="">
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>SMTP</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                    <small id="name13" class="badge badge-default badge-danger form-text text-white">smtp.seudominio.com.br</small>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Login do usuário</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                    <small id="name13" class="badge badge-default badge-danger form-text text-white">usuario</small>
                                                </div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>E-mail</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                </div>
                                                <div class="col-2 col-md-2 col-lg-2">
                                                    <div class="form-group">
                                                        <label>Porta de saída</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Nome de exibição</label>
                                                        <input type="text" class="form-control" value="">
                                                    </div>
                                                </div>
                                                <div class="col-12"></div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Senha </label>
                                                        <input type="password" class="form-control" id="passtext" placeholder="********">
                                                    </div>
                                                </div>
                                                <div class="col-12"></div>
                                                <div class="col-12 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Confirmar senha </label>
                                                        <input type="password" class="form-control" id="passtext" placeholder="********">
                                                    </div>
                                                    <small id="name1" class="badge badge-default badge-info form-text text-white float-left">Deve ter entre 8 e 20 caracteres.</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-actions">
                                            <div class="text-right">
                                                <button type="submit" class="btn btn-info">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import EnterpriseConfigService from "@/services/resources/EnterpriseConfigService";
const service = EnterpriseConfigService.build();

export default {
  data() {
    return {
      name: null,
      color: null,
      status: [],
      search: ''
    };
  },
  methods: {
    goSearch(){
        if(this.search.length == 0)
            this.fetch();

        this.status = this.status.filter(elem => {
            return elem.name.toLowerCase().includes(this.search.toLowerCase());
        });
    },
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {

        let data = {
          name: this.name,
          color: this.color,
          type: 'STATUS'
        }
        
          service
          .create(data)
            .then(resp => {
                this.$bvToast.toast('Sua configuração foi criada com sucesso!', {
                  title: 'Configuração criada',
                  autoHideDelay: 5000,
                  type: 'success'
                })
                this.fetch();
            })
            .catch(err => {
              console.log(err)
            })
          }
      });
    },
    checkField(){
        return this.status.filter(elem => {
            return elem.name.length == 0;
        }).length;
    },
    update(){

        if(this.checkField()){
            this.$bvToast.toast('Algum campo está inválido, por favor, verifique.', {
                title: 'Campo inválido',
                autoHideDelay: 5000,
                type: 'danger'
            })
            return;
        }

        let data = {
          id: 0,
          config: this.status
        }
    
        service
        .update(data)
        .then(resp => {
            this.$bvToast.toast('Sua configuração foi atualizada com sucesso!', {
                title: 'Configuração atualizada',
                autoHideDelay: 5000,
                type: 'success'
            })
        })
        .catch(err => {
            console.log(err)
        })
    },
    fetch(){
        let data = {
            type: 'STATUS'
        }

        service
        .search(data)
        .then(resp => {
            this.status = resp;
        })
        .catch(err => {
            console.log(err)
        })
    }

  },
  mounted() {
      this.fetch();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>